import React, { useState, useEffect } from "react"
import styled from 'styled-components';
import { ContentItem } from "./GridContainer";
import ScoreSlider from "./ScoreSlider";
import { getInfoByScore, formatNum } from "../helpers/ScoreHelper";

const RootContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 428px) {
        grid-template-columns: 1fr;
    }
`;

const getDetailsKey = comp => {
    return `${comp.name}-${comp.type}`;
}

const PROP_FULL = {
    cd: 'condos',
    th: 'townhouses',
    ho: 'houses'
}

const getTypeLabel = (comp, listing) => {
    let allOrOther = 'other';
    let count = `${formatNum(comp.stats.count)} `;
    let label = '';
    const typeParts = comp.type.split('_');
    
    if(comp.name.indexOf('school_') === 0 || comp.name.indexOf('rental_') === 0) {
        count = ''
        if(comp.name.indexOf('_overall') !== -1) {
            allOrOther = 'all';
        }
        if(comp.name.indexOf('school_') === 0) {
            label += 'schools';
        } else if(comp.name.indexOf('rental_ratio') === 0) {
            label += 'price to rent estimates';
        } else {
            label += 'rental estimates';
        }
    } else if(typeParts.length === 4 && typeParts[0] === 'prop' && typeParts[1] === 'size') {
        if(comp.name.indexOf('_overall') !== -1) {
            allOrOther = `all ${formatNum(comp.stats.count)}`;
            count = ''
        }
        label += `similar size ${PROP_FULL[typeParts[2]]}`;
    } else if(typeParts.length === 4 && typeParts[0] === 'lot' && typeParts[1] === 'size') {
        if(comp.name.indexOf('_overall') !== -1) {
            allOrOther = `all ${formatNum(comp.stats.count)}`;
            count = ''
        }
        label += `${PROP_FULL[typeParts[2]]} with similar lot size`;
    }  else if(comp.name.indexOf('other_prop_sqft') === 0) {
        if(comp.name.indexOf('_overall') !== -1) {
            allOrOther = `all ${formatNum(comp.stats.count)}`;
            count = ''
        }
        label += `${PROP_FULL[typeParts[0]]}`;
    } else if(typeParts.length === 2 && typeParts[1] === 'sqft') {
        label += `${PROP_FULL[typeParts[0]]} by square footage`;
    } else if(typeParts.length === 2) {
        label += `${listing.beds}-bedrooms ${PROP_FULL[typeParts[0]]}`;
    } else {
        label += PROP_FULL[typeParts[0]];
    }
    label += '</b>';
    return `<b>${count}${allOrOther} ${label}`;
}

const getLabel = (key, comp) => {
    let label = `This property <u>${key}</u> is`;
    const scoreInfo = getInfoByScore(comp.percentile);
    if(scoreInfo.altLabelPre.length > 0) {
        label += ` ${scoreInfo.altLabelPre}` 
    }
    label += ` <b>${scoreInfo.altLabel}</b>`;
    label += ' compared to';
    return label;
}

const COMPONENT_NAMES = {
    other_prop_sqft_overall: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('size', comp)} ${getTypeLabel(comp, listing)}`;
    }, 
    price_lot_size_type_overall: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('price', comp)} ${getTypeLabel(comp, listing)}`;
    }, 
    price_prop_size_type_overall: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('price', comp)} ${getTypeLabel(comp, listing)}`;
    }, 
    price_prop_size_type_city: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('price', comp)} ${getTypeLabel(comp, listing)} in the city of <b><i>${listing.city}</i></b>`;
    }, 
    price_prop_size_type_zip: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('price', comp)} ${getTypeLabel(comp, listing)} in the zip code of <b><i>${listing.zip}</i></b>`;
    }, 
    price_city: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('price', comp)} ${getTypeLabel(comp, listing)} in the city of <b><i>${listing.city}</i></b>`;
    }, 
    price_zip: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('price', comp)} ${getTypeLabel(comp, listing)} in the zip code of <b><i>${listing.zip}</i></b>`;
    },
    school_overall: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('school', comp)} ${getTypeLabel(comp, listing)}`;
    },
    school_city: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('school', comp)} ${getTypeLabel(comp, listing)} in the city of <b><i>${listing.city}</i></b>`;
    },
    rental_value_overall: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('rental estimate', comp)} ${getTypeLabel(comp, listing)}`;
    },
    rental_value_city: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('rental estimate', comp)} ${getTypeLabel(comp, listing)} in the city of <b><i>${listing.city}</i></b>`;
    },
    rental_value_zip: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('rental estimate', comp)} ${getTypeLabel(comp, listing)} in the zip cod of <b><i>${listing.zip}</i></b>`;
    },
    rental_ratio_overall: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('price to rent estimate', comp)} ${getTypeLabel(comp, listing)}`;
    },    
    rental_ratio_city: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('price to rent estimate', comp)} ${getTypeLabel(comp, listing)} in the city of <b><i>${listing.city}</i></b>`;
    },
    rental_ratio_zip: (comp, listing, details) => {
        details.key = getDetailsKey(comp);
        details.info = `${getLabel('price to rent estimate', comp)} ${getTypeLabel(comp, listing)} in the zip cod of <b><i>${listing.zip}</i></b>`;
    },
}

const getCompDetails = (comp, listing) => {
    if(COMPONENT_NAMES[comp.name]) {
        const details = {};
        COMPONENT_NAMES[comp.name](comp, listing, details);
        details.score = comp.percentile;
        const colorInfo = getInfoByScore(details.score)
        details.color = colorInfo.colorCode;
        return details;
    }
    return null;
}

const orderRatingByZipCityAll = key => {
    if(key.indexOf('_zip-') !== -1) {
        key = `c${key}`;
    } else if(key.indexOf('_city-') !== -1) {
        key = `b${key}`;
    } else {
        key = `a${key}`;
    } 
    return key;
}

const orderRatingKey = key => {
    let newKey = orderRatingByZipCityAll(key);
    if(key.indexOf('price_') === 0) {
        const parts = key.split('_');
        if(parts.length > 4) {
            newKey = `d${newKey}`;
        } else {
            newKey = `e${newKey}`;
        }
    } else if(key.indexOf('school_') === 0) {
        newKey = `c${newKey}`;
    } else if(key.indexOf('rental_') === 0) {
        newKey = `b${newKey}`;
    } else if(key.indexOf('other_') === 0) {
        newKey = `a${newKey}`;
    }

    return newKey;
}

const ListingRatingDetails = props => {

    const { ratings, listing } = props;
    const [comps, setComps] = useState([]);

    useEffect(() => {
        const newComps = [];
        if(ratings.components) {
            ratings.components.forEach(element => {
                const details = getCompDetails(element, listing);
                if(details) {
                    newComps.push(details);
                }
            });

            newComps.sort((a, b) => {
                const aKey = orderRatingKey(a.key);
                const bKey = orderRatingKey(b.key);
                return bKey.localeCompare(aKey)
            });
        }
        setComps(newComps);
    }, [ratings, listing]);

    return (
        <RootContainer>
            {comps && comps.map(item => {
                return (
                    <ContentItem key={item.key}>
                        <ScoreSlider height={10} score={item.score} color={item.color} />
                        <div dangerouslySetInnerHTML={{__html: item.info}} />
                    </ContentItem>
                )
            })}
        </RootContainer>
    )
}

export default ListingRatingDetails;