import React from "react"
import { Bar } from 'react-chartjs-2';
import { getColor, toColorStr } from '../helpers/DefaultSettings'
import ChartContainer from './ChartContainer'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const createDiagonalPattern = (color = 'black') => {
    // create a 10x10 px canvas for the pattern's base shape
    let shape = document.createElement('canvas')
    shape.width = 5
    shape.height = 5
    // get the context for drawing
    let c = shape.getContext('2d')
    // draw 1st line of the shape 
    c.strokeStyle = color
    c.beginPath()
    c.moveTo(1, 0)
    c.lineTo(5, 4)
    c.stroke()
    // draw 2nd line of the shape 
    c.beginPath()
    c.moveTo(0, 4)
    c.lineTo(1, 5)
    c.stroke()
    // create the pattern from the shape
    return c.createPattern(shape, 'repeat')
}

const createConf = (mode, type, valueIdx, medianIdx, data) => {
    const colors = getColor(mode);
    return {
        borderColor: (info) => {
            if(info.parsed && info.parsed.x === valueIdx) {
                return toColorStr(colors['price'])
            } else if(info.parsed && info.parsed.x === medianIdx) {
                return toColorStr(colors[type], 1)
            }
            return toColorStr(colors[type], 0.5)
        },
        borderWidth: (info) => {
            if(info.parsed && info.parsed.x === valueIdx) {
                return 2;
            }
            return 1;
        },
        data,
        backgroundColor: (info) => {
            if(info.parsed && info.parsed.x === medianIdx) {
                return createDiagonalPattern(toColorStr(colors[type]));
            } else if(info.parsed && info.parsed.x === valueIdx) {
                return toColorStr(colors[type], 0.4);
            }
            return toColorStr(colors[type], 0.6);
        }
    }

}

const getChartStat = (theStats, key) => {
    if(theStats.charts[key] && theStats.charts[key].chartData) {
        return theStats.charts[key];
    }
    return {
        chartData: { x: [], y: [] },
        count: 0,
        median: 0
    }
}

const MultiTimeFrameStatChart = props => {

    if(!props.stats) {
        return (
            <ChartContainer mode={props.mode} centerText={'Not Available'} />
        )
    }
    
    const theStats = JSON.parse(JSON.stringify(props.stats));
    const title = props.title || 'Overall Price';
    const mode = props.mode || 'default';
    const itemLabel = props.itemLabel || 'Property Price';

    const title_sub = theStats.name? `: ${theStats.name}` : ''; 
    const options = {
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: title + title_sub,
            font: {
                size: 22,
                weight: 'normal'
            }
          },
        },
        scales: {
            x: {
                grid: {
                    drawTicks: false,
                    drawOnChartArea: false
               }
            }, 
            y: {
                grid: {
                    drawTicks: true,
                    drawOnChartArea: true
               },
               ticks: {
                    callback: value => {
                        return value + '%'; // convert it to percentage
                    },
               }
            }
        }
    };

    const dataType = props.dataType || 'price';

    const allKey = dataType + '_all';
    const yearKey = props.year? dataType + '_' + props.year : allKey;
    const monthKey = props.month? dataType + '_' + props.month : allKey;
    
    const allCharts = getChartStat(theStats, allKey);

    const labels = allCharts.chartData.x
    
    const allData = allCharts.chartData.y;

    const yearCharts = getChartStat(theStats, yearKey);
    const yrData = props.year? yearCharts.chartData.y : [...allData];

    const monthCharts = getChartStat(theStats, monthKey);
    const mnData = props.month? monthCharts.chartData.y : [...allData];
 
    const removedLabels = {};
    
    const countAll = allCharts.count;
    const countYr = yearCharts.count;
    const countMn = monthCharts.count;

    if(props.normalize === true && allData.length > 5) {
        const itemPerBucket = Math.floor(countAll/10)
        let running = 0;
        let runningYr = 0;
        let runningMn = 0;
        let idx = 0;

        do {
            let update = running + allData[0];
            if(update < itemPerBucket) {
                running += allData.shift()
                runningYr += yrData.shift()
                runningMn += mnData.shift()
            } else {
                break;
            }
            idx++;
        } while(idx < 10);
        allData[0] += running;
        yrData[0] += runningYr;
        mnData[0] += runningMn;

        let labelParts = null;
        if(idx > 0) {
            const removedKeys = []
            for(let i=0; i<idx; i++) {
                removedKeys.push(labels.shift());
            }

            const oldLabel = labels[0];
            labelParts = oldLabel.split('-');
            labels[0] = ' < ' + labelParts[labelParts.length-1];
            removedLabels[oldLabel] = labels[0];
            removedKeys.forEach(item => {
                removedLabels[item] = labels[0];
            });
        }

        running = 0;
        runningYr = 0;
        runningMn = 0;
        idx = 0;
        do {
            let update = running + allData[allData.length-1];
            if(update < itemPerBucket) {
                running += allData.pop()
                runningYr += yrData.pop()
                runningMn += mnData.pop()
            } else {
                break;
            }
            idx++;
        } while(allData.length > 6);

        allData[allData.length-1] += running;
        yrData[yrData.length-1] += runningYr;
        mnData[mnData.length-1] += runningMn;

        if(idx > 0) {
            const removedKeys = [];
            for(let i=0; i<idx; i++) {
                removedKeys.push(labels.pop());
            }

            const oldLabel = labels[labels.length-1];
            labelParts = oldLabel.split('-');
            labels[labels.length-1] = ' > ' + labelParts[0] + labelParts[1].substring(labelParts[1].length-1);
            removedLabels[oldLabel] = labels[labels.length-1];
            removedKeys.forEach(item => {
                removedLabels[item] = labels[labels.length-1];
            });
        }   
    
    }

    for(let i=0; i < allData.length; i++) {
        allData[i] = Math.round(allData[i]/countAll*100);
        yrData[i] = Math.round(yrData[i]/countYr*100);
        mnData[i] = Math.round(mnData[i]/countMn*100);
    }

    let medianAll = allCharts.chartData.median;
    let medianYr = yearCharts.chartData.median;
    let medianMn = monthCharts.chartData.median;
    medianAll = removedLabels[medianAll] || medianAll;
    medianYr = removedLabels[medianYr] || medianYr;
    medianMn = removedLabels[medianMn] || medianMn;

    const medianIdxForAll = labels.indexOf(medianAll);
    const medianIdxForYear = labels.indexOf(medianYr);
    const medianIdxForMonth = labels.indexOf(medianMn);
    
    let value = allCharts.chartData.value;
    value = removedLabels[value] || value;

    const valueIdx = labels.indexOf(value);

    const datasets = [
        createConf(mode, 'all', valueIdx, medianIdxForAll, allData)
    ];
    if(yearKey !== allKey) {
        datasets.push(createConf(mode, 'yr', valueIdx, medianIdxForYear, yrData));
    }

    if(monthKey !== allKey) {
        datasets.push(createConf(mode, 'mn', valueIdx, medianIdxForMonth, mnData));
    }

    const data = { labels, datasets };
    const allLegend = `All (${countAll})`;
    const yrLegend = `${props.yearLabel || props.year} (${countYr})`;
    const mnLegend = `${props.month} (${countMn})`;

    return (
        <ChartContainer mode={props.mode} theChart={
            <Bar options={options} data={data} />
        } legend={
            <>
                <div className="all">
                    <div className="color"></div>
                    <div className="label">{allLegend}</div>
                </div>
                {allKey !== yearKey &&
                    <div className="yr">
                        <div className="color"></div>
                        <div className="label">{yrLegend}</div>
                    </div>
                }
                {allKey !== monthKey &&
                    <div className="mn">
                        <div className="color"></div>
                        <div className="label">{mnLegend}</div>
                    </div>
                }
                <div className="median">
                    <div className="color"></div>
                    <div className="label">Median</div>
                </div>
                <div className="price">
                    <div className="color"></div>
                    <div className="label">{itemLabel}</div>
                </div>
            </>
        } />
    )
}

export default MultiTimeFrameStatChart