import React from "react"
import styled from 'styled-components';
import diagonalBg from "../img/diagonal.png"
import { defaultSettings, getColor, toColorStr } from '../helpers/DefaultSettings'
import { getInfoByScoreIndex } from '../helpers/ScoreHelper'
import {
    Chart as ChartJS
} from 'chart.js';

const DEFAULT_SETTINGS = defaultSettings(['font']);

ChartJS.defaults.font.size = DEFAULT_SETTINGS.flattened['font-size'];
ChartJS.defaults.font.family = DEFAULT_SETTINGS.flattened['font-family'];
ChartJS.defaults.font.color = DEFAULT_SETTINGS.flattened['font-color'];

const RootContainer = styled.div`
    position: relative;
    margin: 0px 0px 40px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${DEFAULT_SETTINGS.flattened['font-family']};
    font-size: ${DEFAULT_SETTINGS.flattened['font-size']};
    color: ${DEFAULT_SETTINGS.flattened['font-color']};
    padding: 0px 30px;
`;

const CenterTextContainer = styled.div`
    position: absolute;
    min-width: 50px;
    min-height: 50px;
    left: '50%';
    text-align: center;
    font-size: 35px;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    .score {
        
    }

    .label {
        text-transform: uppercase;
    }
    
`

const LegendContainer = styled.div`
    position: absolute;
    bottom: -25px;
    margin: 0;
`;

const LegendList = styled.div`
    display: flex;

    .color {
        width: 30px;
        border-width: 2px;
        border-style: solid;
        margin: 0px 10px;
    }

    .label {
        text-transform: uppercase;
        font-size: 12px;
    }

    .median {
        display: flex;
        .color {
            border-width: 1px;
            background: url(${diagonalBg});
        }
    }

    .price {
        display: flex;
        .color {
            border-color: ${props => toColorStr(getColor(props.mode, 'price'))};
        }
    }

    .all {
        display: flex;
        .color {
            border-color: ${props => toColorStr(getColor(props.mode, 'all'))};
            background-color: ${props => toColorStr(getColor(props.mode, 'all'))};
        }
        
    }

    .yr {
        display: flex;
        .color {
            border-color: ${props => toColorStr(getColor(props.mode, 'yr'))};
            background-color: ${props => toColorStr(getColor(props.mode, 'yr'))};
        }
    }

    .mn {
        display: flex;
        .color {
            border-color: ${props => toColorStr(getColor(props.mode, 'mn'))};
            background-color: ${props => toColorStr(getColor(props.mode, 'mn'))};
        }
    }

    .a_grade {
        display: flex;
        .color {
            border-color: ${props => getInfoByScoreIndex(0).color};
            background-color: ${props => getInfoByScoreIndex(0).color};
        }
    }

    .b_grade {
        display: flex;
        .color {
            border-color: ${props => getInfoByScoreIndex(1).color};
            background-color: ${props => getInfoByScoreIndex(1).color};
        }
    }

    .c_grade {
        display: flex;
        .color {
            border-color: ${props => getInfoByScoreIndex(2).color};
            background-color: ${props => getInfoByScoreIndex(2).color};
        }
    }

    .d_grade {
        display: flex;
        .color {
            border-color: ${props => getInfoByScoreIndex(3).color};
            background-color: ${props => getInfoByScoreIndex(3).color};
        }
    }
`

const ChartContainer = props => {

    const theChart = props.theChart;
    const legend = props.legend;

    const centerText = props.centerText? (
        <CenterTextContainer>{props.centerText}</CenterTextContainer>
    ) : null;
    return (
        <RootContainer>
            {centerText}
            {theChart}
            {legend &&
                <LegendContainer>
                    <LegendList mode={props.mode}>
                        {legend}
                    </LegendList>
                </LegendContainer>
            }

        </RootContainer>
    )
}

export default ChartContainer