import React from "react";
import ListingDetails from '../components/ListingDetails';

const propertyListingDetailsTemplate = props => {
    const { pageContext } = props;
    const { info, element, estimate, itemIndex } = pageContext;
  
  return (
    <ListingDetails listing={info} info={element}
      itemIndex={itemIndex} estimate={estimate} />
  )
}
export default propertyListingDetailsTemplate;
