import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PageComp from "./PageComp";
import GridContainer, { ImageItem, ContentItem } from "./GridContainer";
import {  getInfoByScore, formatNum } from "../helpers/ScoreHelper";
import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faMoneyBill, faCity, faRuler } from "@fortawesome/free-solid-svg-icons";
import { formatPrice } from "../helpers/PriceHelper";
import { window } from "browser-monads";
import ListingRatingDetails from "./ListingRatingDetails";
import ListingCharts from "./ListingCharts";
import { Helmet } from "react-helmet";
import Paginator from '../components/Paginator';
 
const RootContainer = styled.div`
    
    .listing-primary-section {
        
        > div {
            margin: 0px 7px;
        }

        .listing-header {
            padding-top: 10px;
            font-size: 24px;

            &:not(:first-child) {
                margin-top: 40px;
            }
        }
        
        .listing-desc, .listing-rating-details {
            margin-top: 20px;
        }

        .list-secondary-header {
            font-size: 18px;
        }

        .listing-rating-charts {
            .listing-rating-chart {
                margin-top: 60px;
                display: flex;
                justify-content: center;
                > div {
                    width: 85%;
                }
            }
            @media screen and (max-width: 809px) {
                display: none;
            }

        }

        .listing-rating-charts-small {
            @media screen and (min-width: 810px) {
                display: none;
            }

        }
    }

    .listing-secondary-section {
        display: grid;
        grid-auto-rows: min-content;

        > div {
            padding: 5px;

            .score-indicator {
                display: flex;
                justify-content: center;
                align-items: top;

                > span.numeric-value {
                    padding-top: 2px;
                    margin-left: 10px;
                }
            }

            .score-indicator-breakdown {
                display: flex;
                justify-content: left;
                align-items: top;

                > span.numeric-value {
                    padding-top: 2px;
                    margin-left: 10px;
                }

                > span:first-child {
                    font-size: 18px;
                    min-width: 80px;
                }
            }

        }
        @media screen and (min-width: 810px) and (max-width: 1080px) {
            grid-template-columns: 1fr 1fr;
        }
    
        @media screen and (min-width: 1080px) {
            grid-template-columns: 1fr;
        }
    }

    .content-container {
        padding: 20px;

        > div {
            margin-bottom: 20px;
        }

        .property-details-title {
            font-size: 22px;
        }

        hr {
            margin: 40px 0px;
        }

        .property-details-summary {
            
            > div {
                margin-top: 20px;
                display: flex;
            }

            .property-summary-icon {
                padding: 15px;
                background: rgba(100, 100, 100, 0.3);
                border-radius: 5px;
            }

            .property-summary-text {
                margin-top: 5px;
                margin-left: 20px;

                > div:first-child {
                    font-size: 16px;
                    font-weight: 450;
                }

                > div:nth-child(2) {
                    font-weight: 350;
                }
            }

            .property-summary-price {
                > div:first-child {
                    font-size: 22px;
                }
            }
        }
    }

    .score-recommendation {
        text-align: center;
        text-transform: uppercase;

    }
`;

const getRecommendedLabel = score => {
    const { color, label } = getInfoByScore(score, true);
    return <div style={{color: color, textAlign: 'center', fontSize: '18px'}}>{label}</div>;
}

const ListingDetails = props => {
    
    const [showIcon, setShowIcon] = useState(false);

    const listing = props.listing;
    const info = props.info;
    const estimate = props.estimate;
    const itemIndex = props.itemIndex;
    const scoreInfo = getInfoByScore(listing.score_overall);
    const priceScoreInfo = getInfoByScore(listing.score_price);
    const schoolScoreInfo = getInfoByScore(listing.score_school);
    const rentalScoreInfo = getInfoByScore(listing.score_rental);
    const otherScoreInfo = getInfoByScore(listing.score_other || 0);

    useEffect(() => {
        const toVar = setTimeout(() => {
            setShowIcon(true);
        }, 100);

        return () => {
            clearTimeout(toVar);
        }
    });

    const pager = estimate? (
        <Paginator noNextPrevious={true} totalPages={estimate.all.length} currentPage={itemIndex+1} 
             compact={true} getLabel={page => {
                if(page === 1) {
                    return '-$$';
                } else if(page === 2) {
                    return '-$';
                } else if(page === 4) {
                    return '+$';
                } else if(page === 5) {
                    return '+$$';
                } 
                return '$';
             }} onPageChange={ newPage => {
                /*
                const diff = newPage - 4;
                const pre = diff === 0? '' : diff > 0? 'p' : 'n';
                document.location.href = `../${pre}${Math.abs(diff)}`;
                */
                document.location.href = estimate.all[newPage-1].item.path;
                return true;
             } } />
    ) : null;

    return (
        <PageComp>
            <Helmet>
                <meta property="og:title" content={`${listing.address}-${listing.summary}`} />
                <meta property="og:image" content={listing.pic || ''} />
                <meta property="og:url" content={info.url} />
                <title>{listing.address}</title>
            </Helmet>
            <RootContainer>
                {pager}
                <GridContainer mode={'details-page'}>
                    <div className={'listing-secondary-section'}>
                        <div>
                            <ImageItem image={listing.pic} mode={'details-page'} onClick={() => {
                                    window.open(`https://www.redfin.com${info.ratings.info.url}`, 'external');
                                }}>
                                <div className={'score-indicator'}>
                                    <Rating ratingValue={listing.score_overall} size={23} fillColor={scoreInfo.color} />
                                    <span className={'numeric-value'}>({scoreInfo.score}/5)</span>
                                </div>
                                <div className={'score-recommendation'} style={{color: scoreInfo.color}}>
                                    {getRecommendedLabel(scoreInfo.score)}
                                </div>
                            </ImageItem>
                        </div>
                        <div>
                            <ContentItem>
                                <div className={'property-details-title'}>Property Summary</div>
                                <div className={'property-details-summary'}>
                                    <div>
                                        <span className={'property-summary-icon'}>
                                            {showIcon && <FontAwesomeIcon icon={faMoneyBill} />}
                                        </span>
                                        <div className={'property-summary-text property-summary-price'}>
                                            <div>{formatPrice(listing.price)}</div>
                                            <div>Price</div>
                                        </div>
                                    </div>
                                    <div>
                                        <span className={'property-summary-icon'}>
                                            {showIcon && <FontAwesomeIcon icon={faCity} />}
                                        </span>
                                        <div className={'property-summary-text'}>
                                            <div>{listing.city} {listing.zip}</div>
                                            <div>City, State, Zip</div>
                                        </div>
                                    </div>
                                    <div>
                                        <span className={'property-summary-icon'}>
                                            {showIcon && <FontAwesomeIcon icon={faBed} />}
                                        </span>
                                        <div className={'property-summary-text'}>
                                            <div>{listing.beds}, {listing.beds}</div>
                                            <div>Beds, Baths</div>
                                        </div>
                                    </div>
                                    <div>
                                        <span className={'property-summary-icon'}>
                                            {showIcon && <FontAwesomeIcon icon={faRuler} />}
                                        </span>
                                        <div className={'property-summary-text'}>
                                            <div>{formatNum(info.ratings.info.sqft)} and {
                                                info.ratings.info.lotSize === 'N/A' || !info.ratings.info.lotSize? 
                                                'N/A' : formatNum(info.ratings.info.lotSize)
                                            }</div>
                                            <div>Sq Ft and Lot Size</div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className={'property-details-title'}>Rating Breakdown</div> 
                                
                                <div className={'score-indicator-breakdown'}>
                                    <span>Price</span>
                                    <Rating ratingValue={listing.score_price} size={23} fillColor={priceScoreInfo.color} />
                                    <span className={'numeric-value'}>({priceScoreInfo.score}/5)</span>
                                </div>
                                <div className={'score-indicator-breakdown'}>
                                    <span>School</span>
                                    <Rating ratingValue={listing.score_school} size={23} fillColor={schoolScoreInfo.color} />
                                    <span className={'numeric-value'}>({schoolScoreInfo.score}/5)</span>
                                </div>
                                <div className={'score-indicator-breakdown'}>
                                    <span>Rental</span>
                                    <Rating ratingValue={listing.score_rental} size={23} fillColor={rentalScoreInfo.color} />
                                    {listing.score_rental > 0 && (
                                        <span className={'numeric-value'}>({rentalScoreInfo.score}/5)</span>
                                    )}                                    
                                    {listing.score_rental === 0 && (
                                        <span className={'numeric-value'}>(N/A)</span>
                                    )}
                                </div>
                                <div className={'score-indicator-breakdown'}>
                                    <span>Others</span>
                                    <Rating ratingValue={listing.score_other} size={23} fillColor={otherScoreInfo.color} />
                                    {listing.score_other > 0 && (
                                        <span className={'numeric-value'}>({otherScoreInfo.score}/5)</span>
                                    )}                                    
                                    {listing.score_other === 0 && (
                                        <span className={'numeric-value'}>(N/A)</span>
                                    )}
                                </div>
                            </ContentItem>
                        </div>
                    </div>
                    <div className={'listing-primary-section'}>
                        <div className={'listing-header'}>{listing.address}</div>
                        <div className={'listing-secondary-header'}>{listing.summary}</div>
                        <div className={'listing-desc'}>
                            <div dangerouslySetInnerHTML={{__html: info.ratings.info.remarks}} />
                        </div>
                        <div className={'listing-header'}>Rating Details</div>
                        <div className={'listing-rating-details'}>
                            <ListingRatingDetails listing={listing} ratings={info.ratings} />
                        </div>
                        {false && (
                            <>
                            <div className={'listing-rating-charts'}>
                                <ListingCharts {...props} />
                            </div>
                            <div className={'listing-rating-charts-small'}>
                                <p>
                                    This can only be view on a larger screen with a minimum of 810 pixels (such as a larger phone, tablet, notebook, or desktop computer).
                                </p>
                            </div>
                            </>
                        )}
                    </div>
                </GridContainer>
            </RootContainer>
        </PageComp>
    )
}

export default ListingDetails;