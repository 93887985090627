import React, { useState, useEffect } from "react"
import styled from 'styled-components';
import { getColor, toColorStr } from "../helpers/DefaultSettings";

const RootContainer = styled.div`
    
position: relative;

    .score-slider {
        display: flex;
        width: 100%;
        height: ${props => props.height + 'px'};
        position:relative;
        border-radius: 20px;
        background: ${props => props.bgColor};

        &:after {
            content:'\';
            position: absolute;
            background: ${props => props.fillColor};
            top:0; bottom:0;
            left:0; 
            width: ${props => props.score + '%'};
            border-radius: 20px;
        }
    }

    .score-slider-info {
        font-size: 16px;
        margin-bottom: 10px;
        min-height: 25px;
        .score-slider-label {
            font-size: 18px;
            font-weight: 400;
        }
    }

    .score-slider-percent {
        position: absolute;
        top: 10px;
        right: 15px;
        font-weight: 450;
        font-style: italic;
    }
}

`;

const ScoreSlider = props => {

    const { height: pHeight, score: pScore, type, label: pLabel, showScore: pShowScore, color } = props;
    const [score, setScore] = useState(0);
    const [height, setHeight] = useState(10);
    const [fillColor, setFillColor] = useState('rgb(0, 0, 0)');
    const [bgColor, setBgColor] = useState('rgba(0, 0, 0, 0.15)');
    const [label, setLabel] = useState('');
    const [showScore, setShowScore] = useState(true);

    useEffect(() => {
        if(pHeight > 0) {
            setHeight(pHeight);
        }
    }, [pHeight]);

    useEffect(() => {
        if(pScore > 0) {
            setScore(pScore);
        }
    }, [pScore]);

    useEffect(() => {
        let color = null
        if(type === 'secondary') {
            color = getColor('bruins', 'all');
        } else if(type === 'red') {
            color = getColor('default', 'price');
        } else {
            color = getColor('default', 'yr');
        }
        setFillColor(toColorStr(color));
        setBgColor(toColorStr(color, 0.15));
    }, [type]);

    useEffect(() => {
        if(color && color.length === 3) {
            setFillColor(toColorStr(color));
            setBgColor(toColorStr(color, 0.15));
        }
    }, [color]);

    useEffect(() => {
        setLabel(pLabel);
    }, [pLabel]);

    useEffect(() => {
        setShowScore(pShowScore !== false);
    }, [pShowScore]);

    return (
        <RootContainer height={height} score={score} 
            fillColor={fillColor} bgColor={bgColor}>
       
            <div className={'score-slider-info'}>
                {label && <span className={'score-slider-label'}>{label}</span>}
            </div>
            {showScore && (
                <div className={'score-slider-percent'} style={{color: fillColor}}>{score}%</div>
            )}
            <div className={'score-slider'}></div>
        </RootContainer>
    )
}

export default ScoreSlider;